import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";
import mixpanel from "mixpanel-browser";

import styles from "./root.module.scss";

import bg from "../../assets/backgrounds/bg.png";
import bgPhone from "../../assets/backgrounds/phone-bg.png";

import logo from "../../assets/logos/shotOrNotLogo.png";

import { ReactComponent as Button1 } from "../../assets/buttons/button1.svg";
import { ReactComponent as Button2 } from "../../assets/buttons/button2.svg";
import { ReactComponent as CloseButton } from "../../assets/modal/close.svg";

import video1 from "../../assets/videos/1.mp4";
import video2 from "../../assets/videos/2.mp4";
import video3 from "../../assets/videos/3.mp4";
import video4 from "../../assets/videos/4.mp4";
import video5 from "../../assets/videos/5.mp4";
import video6 from "../../assets/videos/6.mp4";
import video7 from "../../assets/videos/7.mp4";
import video8 from "../../assets/videos/8.mp4";
import video9 from "../../assets/videos/9.mp4";
import video10 from "../../assets/videos/10.mp4";

const Root: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  const onHowToPlayClickHandler = () => {
    setShowModal(true);
  };

  useEffect(() => {
    const videos = [
      video1,
      video2,
      video3,
      video4,
      video5,
      video6,
      video7,
      video8,
      video9,
      video10,
    ];

    videos.forEach((video) => {
      const preloadedVideo = new Image();
      preloadedVideo.src = video;
    });

    const distinctId: string | null = mixpanel.get_distinct_id();
    if (!distinctId || distinctId.includes("$device")) {
      const uid = uuidv4();
      mixpanel.identify(uid);
    }
  }, []);

  return (
    <div className={styles.container}>
      <picture>
        <source
          media="(min-width: 768px)"
          srcSet={bg}
          className={styles.background}
        />
        <img src={bgPhone} alt="Background" className={styles.background} />
      </picture>
      <img src={logo} className={styles.logo} alt="Logo" />
      <div className={styles.bottom}>
        <Link to="/questions">
          <div className={styles.button}>
            <Button1 />
            <h2>PLAY</h2>
          </div>
        </Link>
        <div
          className={`${styles.button} ${styles.button2}`}
          onClick={onHowToPlayClickHandler}
        >
          <Button2 />
          <h2 className={styles.howToPlay}>HOW TO PLAY</h2>
        </div>
      </div>
      {showModal ? (
        <>
          <div className={styles.overlay} onClick={() => setShowModal(false)} />
          <div className={styles.modal}>
            <CloseButton
              onClick={() => setShowModal(false)}
              className={styles.close}
            />
            <div className={styles.inner}>
              <h2 className={styles.howToPlay}>HOW TO PLAY</h2>
              <div className={styles.paragraphs}>
                <p>1. YOU'LL SEE A SERIES OF SCREENSHOTS DISPLAYED ON TILES</p>
                <p>2. PREDICT WHAT WILL HAPPEN NEXT</p>
                <p>
                  3. LOOK AT THE VIDEO AND FIND OUT IF YOU PREDICTED CORRECTLY
                </p>
              </div>
              <div
                className={styles.button}
                onClick={() => setShowModal(false)}
              >
                <Button1 />
                <h3>CONTINUE</h3>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Root;
