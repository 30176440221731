import { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import copy from "copy-to-clipboard";

import { Mixpanel } from "../../utils/mixpanel";
import { questions } from "../../utils/questions";

import styles from "./congrats.module.scss";

import logo from "../../assets/logos/shotOrNotLogo.png";
import linkBox from "../../assets/congrats/linkBox.png";
import shareBox from "../../assets/congrats/shareBox.png";
import star from "../../assets/congrats/star.png";
import { ReactComponent as Beams } from "../../assets/congrats/beams.svg";
import { ReactComponent as CloseButton } from "../../assets/modal/close.svg";
import { ReactComponent as Button1 } from "../../assets/buttons/button1.svg";

const Congrats: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (showModal) {
      const timeoutId = setTimeout(() => {
        setShowModal(false);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [showModal]);

  const { state } = useLocation();

  useEffect(() => {
    if (!state?.options?.length) return;

    const outcomes = [];
    for (let i = 0; i < state.options.length; i++) {
      const element = state.options[i];
      const outcome = element.choice === questions[i].outcome;
      outcomes.push(outcome);
    }

    const winOutcomes = state.options.filter(
      (i: SelectedOptions) => i.outcome === "Correct"
    );

    Mixpanel.track("Questions guessed", {
      "Correct answers": winOutcomes.length,
    });
  }, []);

  const link = `shotornot.xyz`;

  const onShareClickHandler = async () => {
    try {
      if (!state?.options?.length) return null;

      const answers = state.options.map((i: SelectedOptions) =>
        i.outcome === "Correct" ? "🏀" : "🗑️"
      );

      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
      });

      const winOutcomes = state?.options?.filter(
        (i: SelectedOptions) => i.outcome === "Correct"
      )?.length;

      const shareText = `Shot or Not - ${formattedDate} - ${winOutcomes || 0}/${
        questions.length
      }
${answers[0]} ${answers[1]} ${answers[2]} ${answers[3]} ${answers[4]}
${answers[5]} ${answers[6]} ${answers[7]} ${answers[8]} ${answers[9]}
shotornot.xyz`;
      copy(shareText);

      setShowModal(true);

      Mixpanel.track("Share", {
        "Text copied": {
          text: shareText,
        },
      });
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

  const renderAnswers = () => {
    if (!state?.options?.length) return null;

    const answers = state.options.map((i: SelectedOptions) =>
      i.outcome === "Correct" ? "🏀" : "🗑️"
    );

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
    });

    const winOutcomes = state?.options?.filter(
      (i: SelectedOptions) => i.outcome === "Correct"
    )?.length;

    return (
      <>
        <h4>
          Shot or Not - {formattedDate} - {winOutcomes || 0}/{questions.length}{" "}
        </h4>
        <h4>
          {answers[0]} {answers[1]} {answers[2]} {answers[3]} {answers[4]}
        </h4>
        <h4>
          {answers[5]} {answers[6]} {answers[7]} {answers[8]} {answers[9]}
        </h4>
      </>
    );
  };

  return (
    <>
      <div className={styles.container}>
        <Beams className={styles.beams} />
        <Link to="/">
          <img src={logo} alt="Logo" className={styles.logo} />
        </Link>
        <div className={styles.starBox}>
          <img src={star} alt="" className={styles.star} />
        </div>
        <div className={styles.text} onClick={onShareClickHandler}>
          <h1>CONGRATS</h1>
        </div>
        <h3 className={styles.congrats}>You're done!</h3>
        <div className={styles.row}>
          <div className={styles.linkBox}>
            <img src={linkBox} alt="" draggable="false" />
            <Link to={`/`}>
              <h3>{link}</h3>
            </Link>
          </div>
          <div className={styles.share} onClick={onShareClickHandler}>
            <img
              src={shareBox}
              alt="Share button"
              className={styles.share}
              draggable="false"
            />
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.inner}>
            <h2>Answers</h2>
            {renderAnswers()}
          </div>
        </div>
      </div>
      {showModal ? (
        <>
          <div className={styles.overlay} onClick={() => setShowModal(false)} />
          <div className={styles.modal}>
            <CloseButton
              onClick={() => setShowModal(false)}
              className={styles.close}
            />
            <div className={styles.inner}>
              <h2>Text copied to clipboard:</h2>
              {renderAnswers()}
              <div
                className={styles.button}
                onClick={() => setShowModal(false)}
              >
                <Button1 />
                <h3>CLOSE</h3>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Congrats;
