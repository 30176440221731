import { useRef, useState } from "react";

import ReactPlayer from "react-player";

import { Mixpanel } from "../../utils/mixpanel";

import { ReactComponent as SelectButton } from "../../assets/selectButton.svg";
import { ReactComponent as MuteIcon } from "../../assets/icons/mute.svg";
import { ReactComponent as NoMuteIcon } from "../../assets/icons/nomute.svg";

import styles from "./card.module.scss";
interface Props {
  item: QuestionData;
  onClick: (prop_id: string, choice: boolean) => void;
  zIndex: number;
  buttonDisabled: boolean;
  setButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const renderSingleChoice = (inputString: string): string => {
  const upperCaseString = inputString.toUpperCase();

  if (upperCaseString.includes("OVER") || upperCaseString.includes("UNDER")) {
    return inputString.replace(/(OVER|UNDER)/i, "$1\n").toUpperCase();
  }

  if (upperCaseString.includes("/") && !upperCaseString.includes(" / ")) {
    return inputString.split("/").join(" / ").toUpperCase();
  }

  return inputString.toUpperCase();
};

const Card: React.FC<Props> = ({
  item,
  onClick,
  zIndex,
  buttonDisabled,
  setButtonDisabled,
}) => {
  const [isShown, setIsShown] = useState<boolean>(true);
  const [showVideo, setShowVideo] = useState(false);
  const [canPlayVideo, setCanPlayVideo] = useState(false);
  const [choice, setChoice] = useState<boolean | null>(null);
  const [isMuted, setIsMuted] = useState(true);

  const videoRef = useRef<ReactPlayer>(null);

  const onItemClick = (choice: boolean) => {
    onClick(item.prop_id, choice);
    setIsShown(false);
  };

  const renderTitle = () => {
    return (
      <h2 style={showVideo ? { paddingTop: "4.2rem" } : {}}>
        {item.description?.toUpperCase()}
      </h2>
    );
  };

  const handleButtonClick = async (direction: "left" | "right") => {
    if (buttonDisabled) return;
    setChoice(direction === "right");
    setShowVideo(true);
    setButtonDisabled(true);
  };

  const onEnd = () => {
    choice != null && onItemClick(choice);
  };

  const renderChoices = () => {
    return (
      <div className={styles.row}>
        <div
          className={styles.button}
          onClick={() => handleButtonClick("left")}
        >
          <SelectButton
            className={styles.selectButton}
            color={choice !== null ? (!choice ? "orange" : "white") : "white"}
          />
          <h4>{renderSingleChoice(item.choiceF)}</h4>
        </div>
        <div
          className={styles.button}
          onClick={() => handleButtonClick("right")}
        >
          <SelectButton
            className={styles.selectButton}
            color={choice !== null ? (choice ? "orange" : "white") : "white"}
          />
          <h4>{renderSingleChoice(item.choiceT)}</h4>
        </div>
      </div>
    );
  };

  if (!isShown) return null;

  return (
    <div className={styles.mainContainer} style={{ zIndex }}>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          {!showVideo ? (
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={item.screenshot}
                className={styles.image}
              />
              <img
                src={item.screenshotMin}
                alt="Screenshot"
                className={styles.image}
              />
            </picture>
          ) : null}
          <ReactPlayer
            url={item.video}
            playsinline
            ref={videoRef}
            muted={isMuted}
            playing={canPlayVideo && showVideo}
            wrapper={({ children }) => (
              <div className={styles.image}>{children}</div>
            )}
            onReady={() => {
              setCanPlayVideo(true);
            }}
            onEnded={onEnd}
            onPlay={() => {
              videoRef.current?.seekTo(0);
            }}
          />
        </div>
        <div className={styles.titleContainer}>
          {renderTitle()}
          {showVideo ? (
            <div className={styles.controls} style={{ opacity: 1 }}>
              <div
                className={styles.skip}
                onClick={() => {
                  onEnd();
                  Mixpanel.track("Video Skipped", {
                    "Video skipped": {
                      question: item.prop_id,
                    },
                  });
                }}
              >
                Skip video
              </div>
              <div
                className={styles.iconContainer}
                onClick={() => {
                  Mixpanel.track("Mute status change", {
                    "New mute status": {
                      newMuteStatus: isMuted ? "Not muted" : "muted",
                    },
                  });
                  setIsMuted((cur) => !cur);
                }}
              >
                {isMuted ? (
                  <MuteIcon className={styles.icon} />
                ) : (
                  <NoMuteIcon className={styles.icon} />
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {renderChoices()}
    </div>
  );
};

export default Card;
