import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import mixpanel from "mixpanel-browser";

import Card from "../../components/card";

import { Mixpanel } from "../../utils/mixpanel";

import styles from "./questions.module.scss";

import logo from "../../assets/logos/shotOrNotHorizontal.png";
import star from "../../assets/star.png";
import questionBar from "../../assets/inside-bar.png";
import { questions } from "../../utils/questions";

const calculateCompletionPercentage = (
  currentQuestion: number,
  totalQuestions: number
): string => {
  const percentage = (currentQuestion / totalQuestions) * 100;
  return `${
    parseFloat(percentage.toFixed(2)) -
    (percentage < 50 ? 0 : percentage < 90 ? 1.5 : 3)
  }%`;
};

const Questions: React.FC = () => {
  const [question, setQuestion] = useState<number>(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions[]>([]);
  const [modal, setModal] = useState({ shown: false, content: "" });

  const navigate = useNavigate();

  useEffect(() => {
    const distinctId: string | null = mixpanel.get_distinct_id();
    if (!distinctId || distinctId.includes("$device")) {
      const uid = uuidv4();
      mixpanel.identify(uid);
    }
  }, []);

  useEffect(() => {
    if (modal.shown) {
      const timeoutId = setTimeout(() => {
        closeModal();
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [modal.shown]);

  const onClickHandler = async (id: string, choice: boolean) => {
    if (!questions) return;

    try {
      const currentQuestion = questions.find((i) => i.prop_id === id);

      const temp = selectedOptions;
      temp.push({
        prop_id: id,
        question: currentQuestion?.description,
        choice: choice ? currentQuestion?.choiceT : currentQuestion?.choiceF,
        outcome: choice === currentQuestion?.outcome ? "Correct" : "Incorrect",
      });
      setSelectedOptions(temp);

      Mixpanel.track("Question answered", {
        Question: currentQuestion?.description,
        Answer: choice ? currentQuestion?.choiceT : currentQuestion?.choiceF,
        Outcome: choice === currentQuestion?.outcome ? "Correct" : "Incorrect",
      });

      if (question + 1 < questions.length) {
        setQuestion((current) => current + 1);
        setButtonDisabled(false);
        if (currentQuestion?.video) {
          setModal({
            shown: true,
            content:
              choice === currentQuestion.outcome
                ? "Correct! 🎉"
                : "Incorrect! 😞",
          });
        }
      } else {
        console.log(JSON.stringify(selectedOptions));
        // const res = await postAnswers(selectedOptions);
        // console.log("RES:" + JSON.stringify(res));
        navigate(`/congrats`, {
          state: { options: selectedOptions },
        });
        Mixpanel.track("Answers submitted", {
          "Answers submitted": selectedOptions,
        });
      }
    } catch (e) {
      console.log("ERROR: " + e);
    } finally {
    }
  };

  const closeModal = () => {
    setModal({ shown: false, content: "" });
  };

  return (
    <div className={styles.container}>
      <Link to="/">
        <img src={logo} alt="Logo" className={styles.logo} />
      </Link>
      <div className={styles.barContainer}>
        <img src={questionBar} alt="" className={styles.bar} />
        <div
          className={styles.barInner}
          style={{
            width: `${calculateCompletionPercentage(
              question + 1,
              questions?.length || 0
            )}`,
          }}
        />
        <div className={styles.questionNumber}>
          QUESTION {question + 1} / {questions?.length}
        </div>
      </div>
      <div className={styles.center}>
        {questions.map((item, index) => (
          <Card
            buttonDisabled={buttonDisabled}
            setButtonDisabled={setButtonDisabled}
            item={item}
            zIndex={questions.length - index}
            key={item.prop_id}
            onClick={onClickHandler}
          />
        ))}
      </div>
      {modal.shown ? (
        <div className={styles.modal} onClick={closeModal}>
          <div className={styles.inner}>
            <img alt="Star" src={star} />
            <div className={styles.content}>
              <h3>{modal.content}</h3>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Questions;
