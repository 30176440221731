import video1 from "../assets/videos/1.mp4";
import video2 from "../assets/videos/2.mp4";
import video3 from "../assets/videos/3.mp4";
import video4 from "../assets/videos/4.mp4";
import video5 from "../assets/videos/5.mp4";
import video6 from "../assets/videos/6.mp4";
import video7 from "../assets/videos/7.mp4";
import video8 from "../assets/videos/8.mp4";
import video9 from "../assets/videos/9.mp4";
import video10 from "../assets/videos/10.mp4";

import screenshot1 from "../assets/screenshots/1.png";
import screenshot2 from "../assets/screenshots/2.png";
import screenshot3 from "../assets/screenshots/3.png";
import screenshot4 from "../assets/screenshots/4.png";
import screenshot5 from "../assets/screenshots/5.png";
import screenshot6 from "../assets/screenshots/6.png";
import screenshot7 from "../assets/screenshots/7.png";
import screenshot8 from "../assets/screenshots/8.png";
import screenshot9 from "../assets/screenshots/9.png";
import screenshot10 from "../assets/screenshots/10.png";

import screenshot1Min from "../assets/screenshots/1-min.png";
import screenshot2Min from "../assets/screenshots/2-min.png";
import screenshot3Min from "../assets/screenshots/3-min.png";
import screenshot4Min from "../assets/screenshots/4-min.png";
import screenshot5Min from "../assets/screenshots/5-min.png";
import screenshot6Min from "../assets/screenshots/6-min.png";
import screenshot7Min from "../assets/screenshots/7-min.png";
import screenshot8Min from "../assets/screenshots/8-min.png";
import screenshot9Min from "../assets/screenshots/9-min.png";
import screenshot10Min from "../assets/screenshots/10-min.png";

export const questions: QuestionData[] = [
  {
    prop_id: "1",
    video: video1,
    screenshot: screenshot1,
    screenshotMin: screenshot1Min,
    description: "Does Steph Curry make this shot or not?",
    choiceF: "Yes",
    choiceT: "No",
    outcome: false,
  },
  {
    prop_id: "2",
    video: video2,
    screenshot: screenshot2,
    screenshotMin: screenshot2Min,
    description: "Will Victor Wembanyama block Trace Jackson-Davis?",
    choiceF: "Yes",
    choiceT: "No",
    outcome: true,
  },
  {
    prop_id: "3",
    video: video3,
    screenshot: screenshot3,
    screenshotMin: screenshot3Min,
    description:
      "How many passes do the Spurs make on this possession as time is winding down?",
    choiceF: "Over 2.5",
    choiceT: "Under 2.5",
    outcome: false,
  },
  {
    prop_id: "4",
    video: video4,
    screenshot: screenshot4,
    screenshotMin: screenshot4Min,
    description: "Does Joel Embiid send this game to overtime?",
    choiceF: "Yes",
    choiceT: "No",
    outcome: false,
  },
  {
    prop_id: "5",
    video: video5,
    screenshot: screenshot5,
    screenshotMin: screenshot5Min,
    description: "Will a player dunk the ball on this possession?",
    choiceF: "Yes",
    choiceT: "No",
    outcome: true,
  },
  {
    prop_id: "6",
    video: video6,
    screenshot: screenshot6,
    screenshotMin: screenshot6Min,
    description: "Does Kemba Walker sink this shot?",
    choiceF: "Yes",
    choiceT: "No",
    outcome: true,
  },
  {
    prop_id: "7",
    video: video7,
    screenshot: screenshot7,
    screenshotMin: screenshot7Min,
    description: "Will this end in a dunk or a block?",
    choiceF: "Dunk",
    choiceT: "Block",
    outcome: false,
  },
  {
    prop_id: "8",
    video: video8,
    screenshot: screenshot8,
    screenshotMin: screenshot8Min,
    description: "Will LeBron shoot this ball?",
    choiceF: "Yes",
    choiceT: "No",
    outcome: true,
  },
  {
    prop_id: "9",
    video: video9,
    screenshot: screenshot9,
    screenshotMin: screenshot9Min,
    description: "Will Luka Doncic score on this possession?",
    choiceF: "Yes",
    choiceT: "No",
    outcome: true,
  },
  {
    prop_id: "10",
    video: video10,
    screenshot: screenshot10,
    screenshotMin: screenshot10Min,
    description:
      "On which cheek does Will Smith slap the shit out of Chris Rock?",
    choiceF: "Left",
    choiceT: "Right",
    outcome: true,
    isBonus: true,
  },
];
